import React from 'react';
import './App.css';
import ScratchCard from './Card/CardOne/ScratchCard'
import MothersDayOne from './Card/MothersDay/MothersDayOne/ScratchCard'
import MothersDayTwo from './Card/MothersDay/MothersDayTwo/ScratchCard'
import MothersDayThree from './Card/MothersDay/MothersDayThree/ScratchCard'
import MothersDayFour from './Card/MothersDay/MothersDayFour/ScratchCard'
import MothersDayFive from './Card/MothersDay/MothersDayFive/ScratchCard'
import NaughtDateNight from './Card/NaughtyDateNight/ScratchCard'
import PositionMission from './Card/PositionMission/ScratchCard'
import BirthdayCard from './Card/BirthdayCard/ScratchCard'
import Demo from './Card/Demo/ScratchCard'
import FathersDayOne from './Card/FathersDay/FathersDayOne/ScratchCard'
import FathersDayTwo from './Card/FathersDay/FathersDayTwo/ScratchCard'
import FathersDayThree from './Card/FathersDay/FathersDayThree/ScratchCard'
import FathersDayFour from './Card/FathersDay/FathersDayFour/ScratchCard'
import FathersDayFive from './Card/FathersDay/FathersDayFive/ScratchCard'
import KamaSutraChallenge from './Card/KamaSutraChallenge/ScratchCard'
import KamaSutraCalendar from './Card/KamaSutraCalendar/ScratchCard'
import NaughtScratchCard1 from './Card/Naughty/NaughtyScratchCard1/ScratchCard'
import NaughtScratchCard2 from './Card/Naughty/NaughtyScratchCard2/ScratchCard'
import NaughtScratchCard3 from './Card/Naughty/NaughtyScratchCard3/ScratchCard'
import NaughtScratchCard4 from './Card/Naughty/NaughtyScratchCard4/ScratchCard'
import NaughtScratchCard5 from './Card/Naughty/NaughtyScratchCard5/ScratchCard'
import NaughtScratchCard6 from './Card/Naughty/NaughtyScratchCard6/ScratchCard'
import NaughtScratchCard7 from './Card/Naughty/NaughtyScratchCard7/ScratchCard'
import NaughtScratchCard8 from './Card/Naughty/NaughtyScratchCard8/ScratchCard'
import NaughtScratchCard9 from './Card/Naughty/NaughtyScratchCard9/ScratchCard'
import NaughtScratchCard10 from './Card/Naughty/NaughtyScratchCard10/ScratchCard'
import NaughtScratchCard11 from './Card/Naughty/NaughtyScratchCard11/ScratchCard'
import NaughtScratchCard12 from './Card/Naughty/NaughtyScratchCard12/ScratchCard'
import NaughtScratchCard13 from './Card/Naughty/NaughtyScratchCard13/ScratchCard'
import NaughtScratchCard14 from './Card/Naughty/NaughtyScratchCard14/ScratchCard'
import NaughtScratchCard15 from './Card/Naughty/NaughtyScratchCard15/ScratchCard'
import Sexmas from './Card/Sexmas/ScratchCard'
import ErrorPage from './Error';
import { useLocation, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react';

function CheckToken({ children }) {
  const [isValidToken, setIsValidToken] = useState(true);
  const location = useLocation();
  
  useEffect(() => {
    let query = new URLSearchParams(location.search);
    let token = query.get('token');

    if (!token || token !== 'vatghrahtbhrba') {
      setIsValidToken(false);
    }
  }, [location]);

  if (!isValidToken) {
    return <ErrorPage />;
  }

  return children;
}

function App() {
  const [isPortrait, setIsPortrait] = useState(true);

  useEffect(() => {
    const handleOrientationChange = () => {
      if (/Mobi|Android/i.test(navigator.userAgent)) {
        setIsPortrait(window.screen.orientation.type.includes('portrait'));
      }
    };

    handleOrientationChange(); // Initial check
    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  if (!isPortrait) {
    return <div>Please rotate your device to portrait mode to view the scratch card.</div>;
  }

  return (
    <Router>
      <CheckToken>
        <Routes>
          {/* Naughty Scratch Card Route */}
          <Route path="/" element={<ScratchCard />} />
          <Route path="/naughty-scratch-card-one" element={<NaughtScratchCard1 />} />
          <Route path="/naughty-scratch-card-two" element={<NaughtScratchCard2 />} />
          <Route path="/naughty-scratch-card-three" element={<NaughtScratchCard3 />} />
          <Route path="/naughty-scratch-card-four" element={<NaughtScratchCard4 />} />
          <Route path="/naughty-scratch-card-five" element={<NaughtScratchCard5 />} />
          <Route path="/naughty-scratch-card-six" element={<NaughtScratchCard6 />} />
          <Route path="/naughty-scratch-card-seven" element={<NaughtScratchCard7 />} />
          <Route path="/naughty-scratch-card-eight" element={<NaughtScratchCard8 />} />
          <Route path="/naughty-scratch-card-nine" element={<NaughtScratchCard9 />} />
          <Route path="/naughty-scratch-card-ten" element={<NaughtScratchCard10 />} />
          <Route path="/naughty-scratch-card-eleven" element={<NaughtScratchCard11 />} />
          <Route path="/naughty-scratch-card-twelve" element={<NaughtScratchCard12 />} />
          <Route path="/naughty-scratch-card-thirteen" element={<NaughtScratchCard13 />} />
          <Route path="/naughty-scratch-card-fourteen" element={<NaughtScratchCard14 />} />
          <Route path="/naughty-scratch-card-fifteen" element={<NaughtScratchCard15 />} />

          {/* Error Route */}
          <Route path="/*" element={<ErrorPage />} />

          {/* Mother's Day Routes */}
          <Route path="/mothers-day-one" element={<MothersDayOne />} />
          <Route path="/mothers-day-two" element={<MothersDayTwo />} />
          <Route path="/mothers-day-three" element={<MothersDayThree />} />
          <Route path="/mothers-day-four" element={<MothersDayFour />} />
          <Route path="/mothers-day-five" element={<MothersDayFive />} />

          {/* Naughty Date Night Route */}
          <Route path="/naughty-date-night" element={<NaughtDateNight />} />

          {/* Position Mission Route */}
          <Route path="/position-mission" element={<PositionMission />} />

          {/* Birthday Card Route */}
          <Route path="/birthday-card" element={<BirthdayCard />} />

          {/* Demo Card Route */}
          <Route path="/demo" element={<Demo />} />

          {/* Fathers's Day Routes */}
          <Route path="/fathers-day-one" element={<FathersDayOne />} />
          <Route path="/fathers-day-two" element={<FathersDayTwo />} />
          <Route path="/fathers-day-three" element={<FathersDayThree />} />
          <Route path="/fathers-day-four" element={<FathersDayFour />} />
          <Route path="/fathers-day-five" element={<FathersDayFive />} />

          {/* Sexmas Card Route */}
          <Route path="/sexmas" element={<Sexmas />} />

          {/* Kama Sutra Card Route */}
          <Route path="/kamasutra-calendar" element={<KamaSutraCalendar />} />
          <Route path="/kamasutra-challenge" element={<KamaSutraChallenge />} />
        </Routes>
      </CheckToken>
    </Router>
  );
}

export default App;
